<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">展商列表</p>
            <p class="btn-wrap-width" @click="doShowAddExhibitorDialog">新增展商</p>
        </div>
        <div class="table-margin-top flex-1-overflow-y">
            <div class="table-header table-margin-bottom">
                <div class="table-index">序号</div>
                <div class="table-name">展商名称</div>
                <div class="table-booth">展位号</div>
                <div class="table-view_points">展位看点</div>
                <div class="table-logo">展商LOGO</div>
                <div class="table-operation">操作</div>
            </div>
            <div v-for="(exhibitor, index) in dataList" :key="index" class="table-item table-margin-bottom">
                <div class="table-index">{{ (index + 1) }}</div>
                <div class="table-name" style="font-weight: bold;">{{ exhibitor.partner.name }}</div>
                <div class="table-booth" style="font-weight: bold;">{{ exhibitor.booth }}</div>
                <div class="table-view_points" v-html="exhibitor.view_points"></div>
                <div class="table-logo">
                    <img :src="exhibitor.partner.logo_url"
                        style="width: 72px;height: auto;max-height: 72px;object-fit: cover;">
                </div>
                <div class="table-operation" style="display: flex;">
                    <p class="list-btn-blue" style="text-align: left;padding-left: 8px;"
                        @click="editExhibitor(exhibitor)">
                        编辑</p>
                    <p class="list-btn-red" style="margin-left: 10px;text-align: left;padding-left: 8px;"
                        @click="doRemoveExhibitor(exhibitor)">删除</p>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="showAddExhibitorDialog" title="添加展商" width="65%">
            <div>
                <el-table style="max-height: 500px;min-height: 350px;" :border="true" :data="filterPartnerList">
                    <el-table-column type="index" prop="index" label="序号"></el-table-column>
                    <el-table-column prop="name" label="品牌名称"></el-table-column>
                    <el-table-column prop="" label="品牌Logo">
                        <template slot-scope="scope">
                            <img :src="scope.row.logo_url" style="width: 48px; height: 48px; object-fit: cover" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="master_email" label="登录邮箱"></el-table-column>
                    <el-table-column prop="" label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" size="small"
                                @click="confirmAddExhibitor(scope.row._id.$id)">添加</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showEditDialog" :title="exhibitorInfo.id == '' ? '添加展商' : '编辑展商'" width="65%">
            <div>
                <el-form :model="exhibitorInfo" ref="exhibitorForm" :rules="rules" label-position="left">
                    <el-form-item label="展位号" prop="booth">
                        <el-input-number v-model="exhibitorInfo.booth" placeholder="请输入"></el-input-number>
                    </el-form-item>
                    <el-form-item label="" prop="view_points">
                        <label>展览看点</label>
                        <quill-editor class="editor" style="margin-bottom:20px" ref="myTextEditor"
                            v-model="exhibitorInfo.view_points" :options="editorOption">
                        </quill-editor>
                    </el-form-item>
                </el-form>
                <div style="text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showEditDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveExhibitor()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import '../../assets/common/common.css'
import {
    updateExhibitor,
    getOrganizerPartners,
    getExhibitors,
    removeExhibitor,
    addMettingExhibitor
} from '../../api/api'
import {
    getUserId
} from '../../utils/store'
export default ({
    name: 'index',
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: [],
            showAddExhibitorDialog: false,
            organizerPartners: [],
            filterPartnerList: [],
            showEditDialog: false,
            exhibitorInfo: {
                id: '',
                booth: '',
                view_points: '',
            },
        }
    },
    mounted() {
        this.fetchData()
        this.requestOrganizerPartners()
    },
    methods: {

        saveExhibitor() {
            this.$refs.exhibitorForm.validate((val) => {
                if (val) {
                    updateExhibitor(this.exhibitorInfo).then((res) => {
                        this.$message.success('保存成功')
                        this.fetchData()
                        this.showEditDialog = false
                    })
                }
            })
        },

        editExhibitor(row) {
            this.exhibitorInfo = {
                id: row._id.$id,
                booth: row.booth,
                view_points: row.view_points,
            }
            this.showEditDialog = true
        },

        requestOrganizerPartners() {
            getOrganizerPartners(getUserId()).then((res) => {
                this.organizerPartners = res.data.data
            })
        },
        doShowAddExhibitorDialog() {
            this.filterPartnerList = []
            for (let i = 0; i < this.organizerPartners.length; i++) {
                let partner = this.organizerPartners[i]
                let enableAdd = true
                for (let j = 0; j < this.dataList.length; j++) {
                    let alreadyPartner = this.dataList[j]
                    if (alreadyPartner._id.$id == partner._id.$id) {
                        enableAdd = false
                        break
                    }
                }
                if (enableAdd == true) {
                    this.filterPartnerList.push(partner)
                }
            }
            this.showAddExhibitorDialog = true
        },
        confirmAddExhibitor(id) {
            addMettingExhibitor(this.meeting_id, id, getUserId()).then((res) => {
                let result = res.data
                if (result.code != 0) {
                    this.$message.warning(result.msg)
                    return
                }
                this.$message.success('添加成功')
                this.fetchData()
                this.showAddExhibitorDialog = false
            })
        },

        fetchData() {
            getExhibitors(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        doRemoveExhibitor(row) {
            this.$alert('你确定要删除此展商吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        removeExhibitor(row._id.$id).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                        })
                    }
                }
            })
        },
    }
})
</script>
<style lang="scss" scoped>
.table-name {
    flex: 2;
}

.table-booth {
    flex: 2;
}

.table-view_points {
    flex: 2;
}

.table-logo {
    flex: 2;
}

.table-operation {
    flex: 1.5;
}



.ql-container {
    height: 160px !important;
}

.logo-uploader {
    width: 178px;
    height: auto;
    object-fit: cover;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
}

.logo-uploader2 {
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    font-size: 28px;
    color: #8c939d;
    object-fit: cover;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
}
</style>